/** LIBS */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

/** CUSTOMS */
import MultiSelect from "components/multiSelect";
import { ToLabelValueFromMap, ToLabelValue } from "lib/strings";
import { fetchPost } from "lib/fetch";

/* CONSTANTS */
import { autocompleteWindowMax } from "constants/models";
import { newId } from "constants/models";

export const MultiSelectAutocomplete2 = forwardRef(({
  placeholder,
  field, 

  value, 
  setValue, 

  label, 
  setLabel, 

  searchUri, 
  searchPostfix,

  isMulti, 
  className,
  
  customSearchParams,
  isCreatable,
  hideEmpty,

  onRemove,
  ...otherProps
}, ref) => {
  const [fieldValues, setFieldValues] = useState(null);
  const [autocompleteController, setController] = useState(null);
  const [valueAutocomplete, setValueAutocomplete] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!field) {
      return;
    }

    if (firstLoad){
      setFirstLoad(false);
      return;
    }

    fieldGet();
  }, [valueAutocomplete]);

  // Expose clearInput method to the parent component via ref
  useImperativeHandle(ref, () => ({
    clearInput: () => {
      setValue(null);
      setLabel(null);
      setFieldValues(null);
      setValueAutocomplete("");
    }
  }));

  const fieldGet = () => {
    if (!searchUri || !searchPostfix){
      return;
    }

    var params = {
      field: field,
      value: valueAutocomplete,
      filters: null,
    };

    if (customSearchParams) {
      params = customSearchParams(valueAutocomplete);
    }

    if (autocompleteController) {
      autocompleteController.abort();
      setController(null);
    }

    const controller2 = new AbortController();
    setController(controller2);

    fetchPost(searchUri + searchPostfix, params, controller2.signal)
      .then((resp) => {
        setFieldValues(resp);
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  if (setValue && !value){
    value = [];
  }

  if (setLabel && !label){
    label = [];
  }

  var displayValue = [];

  if (setValue && !setLabel) {
    displayValue = ToLabelValue(value);
  }

  if (!setValue && setLabel) {
    displayValue = ToLabelValue(label);
  }

  if (setValue && setLabel) {
    for(var i = 0; i < value.length; i++) {
      displayValue = [...displayValue, {label: label[i], value: value[i]}];
    }
  }


  const onCreate = (e) => {
    if (isMulti) {
      if (setLabel){
        const temp = [...label, e[e.length-1].label];
        setLabel(temp);
      }

      if (setValue && !setLabel){
        const temp = [...value, e[e.length-1].value];
        setValue(temp);
      }

      if (setValue && setLabel){
        const temp = [...value, newId];
        setValue(temp);
      }

      return;
    }

    if (setLabel){
      setLabel(e.label);
    }

    if (setValue){
      setValue(newId);
    }
  };

  return (
    <MultiSelect
      {...otherProps}
      placeholder={placeholder}
      isMulti={isMulti}
      className={`${className || ""}`}
      onFocus={() => {
        if (fieldValues) {
          return;
        }

        fieldGet();
      }}
      onClear={() => {
        if (setValue){
          setValue(null);
        }

        if (setLabel){
          setLabel(null);
        }

        setValueAutocomplete("");
      }}
      value={displayValue}

      onSelect={(e) => {
        if (isMulti) {
          if (setLabel){
            const temp = [...label, e[e.length-1].label];
            setLabel(temp);
          }
    
          if (setValue){
            const temp = [...value, e[e.length-1].value];
            setValue(temp);
          }
          return;
        }
    
        if (setLabel){
          setLabel(e.label);
        }
    
        if (setValue){
          setValue(e.value);
        }
      }}

      onCreate={isCreatable ? onCreate : undefined}

      onRemove={(_, meta) => {

        if (!onRemove){
          var index;
          if (setValue){
            const temp = [...value];

            // Label will always be unique, the value (id) can be "NEW", which is not unique.
            index = temp.indexOf(meta.removedValue.label); 
            temp.splice(index, 1);
            setValue(temp);
          }

          if (setLabel){
            const temp = [...label];
            index = temp.indexOf(meta.removedValue.label);
            temp.splice(index, 1);
            setLabel(temp);
          }
        }

        if (onRemove) {
          onRemove(meta);
        }
      }}

      onInputChange={(value, meta) => {
        if (!setValueAutocomplete) {
          return;
        }

        if (value.length > valueAutocomplete.length && (!fieldValues || Object.keys(fieldValues).length < autocompleteWindowMax)) {
          return;
        }

        if (value === "" && (meta.action === "set-value" || meta.action === "menu-close" || meta.action === "input-blur")) {
          return;
        }
        setValueAutocomplete(value);
      }}
      options={ToLabelValueFromMap(fieldValues)}
      noOptionsMessage={() => {
        if (hideEmpty) {
          return null;
        }
      }}
    />
  );
});

MultiSelectAutocomplete2.displayName = "Text";

MultiSelectAutocomplete2.propTypes = {
  placeholder: PropTypes.string.isRequired,

  field: PropTypes.string.isRequired,
  searchUri: PropTypes.string.isRequired,
  searchPostfix: PropTypes.string.isRequired,

  value: PropTypes.array,
  setValue: PropTypes.func,

  label: PropTypes.array,
  setLabel: PropTypes.func,

  isMulti: PropTypes.bool,
  isCreatable: PropTypes.bool,

  className: PropTypes.string, 

  customSearchParams: PropTypes.func,
  hideEmpty: PropTypes.bool,

  onRemove: PropTypes.func,
};

export default MultiSelectAutocomplete2;