/* LIBS */
import React from "react";
import PropTypes from "prop-types";

export default function Button({ className, secondary, disabled, onClick, ...otherProps }) {
  return (
    <button
      disabled={disabled}
      {...otherProps}

      onClick={(e) => {
        if (disabled){
          return;
        }

        if (onClick){
          onClick(e);
        }
      }}
      className={`
        px-6
        py-3
        text-white
        font-semibold
        text-xs
        leading-tight
        transition
        ${
    !secondary
      ? "bg-primaryBlue hover:bg-primaryBlueHover active:bg-primaryBlue"
      : "bg-red-800 hover:bg-red-700 active:bg-red-800"
    }
         transition duration-300 ease 
        disabled:opacity-50
        ${className || ""}`}
    />
  );
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
