import React/*, { StrictMode }*/ from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { WebsocketBackendPath } from "constants/routing/backend";

/* SERVICES */
import { MenuProvider } from "context/menuProvider";
import { ThemeProvider } from "context/themeProvider";
import { UserContextProvider } from "context/user";
import { WebSocketContextProvider } from "context/webSocket";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/*<StrictMode>*/}
    <BrowserRouter>
      <MenuProvider>
        <ThemeProvider>
          <UserContextProvider>
            <WebSocketContextProvider webSocketPath={WebsocketBackendPath}>
              <App />
            </WebSocketContextProvider>
          </UserContextProvider>
        </ThemeProvider>
      </MenuProvider>
    </BrowserRouter>
    {/*</StrictMode>*/}
  </>
);
