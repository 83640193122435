import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ id, className, checked, onChange, label }) => {
  let checkBox = (
    <input
      id={id}
      className={`h-5 w-5 accent-primaryBlue ${className || ""}`}
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
  );

  return (
    <>
      {checkBox}
      <span onClick={() => {
        onChange(!checked);
      }}  className="select-none ml-1">{label ?? label}</span>
    </>
  );
};


export default Checkbox;

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
};
