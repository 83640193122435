/** LIBS */
import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

/* CUSTOMS */
import MultiSelect from "components/multiSelect";
import SeoHeader from "components/seoHeader";
import Title from "components/title";
import Card from "components/card";
import { ToLabelValueFromMap, ToLabelValueFromSource } from "lib/strings";
import {extractTags} from "./data";
import { fetchPost } from "lib/fetch";
import { useNavigate } from "react-router-dom";

/* CONSTANTS */
import Button from "components/button";
import Loader from "components/loader";
import TextBox from "components/textBox";
import MultiSelectAutocomplete2 from "components/multiSelectAutocomplete2";
import { FieldValuesBackendPostfix, ForumBackendPath } from "constants/routing/backend";
import { CreatePostfix, ForumFrontendPath } from "constants/routing/frontend";
import CommentBox from "./commentBox";
import { sleepSeconds } from "lib/sleep";

/* ICONS */

/* SERVICES */
import { UserContext } from "context/user";

export default function TopicCreate() {
  const { tag } = useParams();
  const { userContext } = useContext(UserContext);
  const { username /*, permissions*/ } = userContext;

  const [isLoading, setIsLoading] = useState(false);
  const [postText, setPostText] = useState("");
  const [topicTitle, setTopicTitle] = useState("");
  
  const [tags, setTags] = useState([tag]);
  const [subTags, setSubTags] = useState([]);
  const navigate = useNavigate();

  const initialForumPostCreate = () => {
    setIsLoading(true);

    const body = {
      title: topicTitle,

      tags: tags,
      subTags: subTags,

      initialPostText: postText
    };

    fetchPost(ForumBackendPath + "/topic" + CreatePostfix, body)
      .then(async (resp) => {
        await sleepSeconds(1); // Sleep to wait for ElasticSearch to index
        navigate(`${ForumFrontendPath}/topic/${resp.id}`);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!username) {
    toast.error("You must be logged in.");
    return;
  }

  const aaa = extractTags();

  return (
    <div className="space-y-2 mb-2">
      <SeoHeader pageTitle={"Forum - Start New Topic"} />
      <Title>{"Start New Topic"}</Title>
      <Loader isLoading={isLoading} />

      <Card title="Topic Information">
        <div className="grid grid-cols-12 space-y-4">
          <TextBox
            id={"topicTitle"} 
            className="col-span-12 w-full" 
            placeholder={"Topic Title"} 
            onChange={setTopicTitle} 
          />

          <MultiSelect
            className="col-span-12 "
            placeholder="Tag / Sub-Forum"
            isMulti={false}
            onSelect={(e) => {
              setTags(e.value);
            }}
            options={ToLabelValueFromMap(extractTags())}

            value={ToLabelValueFromSource(tags, ToLabelValueFromMap(aaa))}

            onInputChange={() => {}}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-12 md:col-span-12"
            placeholder="Sub-Tags"
            field="tags"
            searchUri={ForumBackendPath + "/topic"}
            searchPostfix={FieldValuesBackendPostfix}
            label={subTags}
            isMulti={true}
            setLabel={setSubTags}
            isCreatable={true}
          />
        </div>
      </Card>

      <Card title="Starting Post">
        <CommentBox 
          className={"my-2"}
          postText={postText} 
          setPostText={setPostText} 
          
        />
      </Card>

      <div className="flex items-center justify-end">
        <Button className="rounded-lg" onClick={initialForumPostCreate}>
            Post
        </Button>
      </div>
    </div>
  );
}
