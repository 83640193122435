/* LIBS */
import React, { useState } from "react";
import PropTypes from "prop-types";

/* CUSTOMS */
import Paginator from "components/paginator";
import { Table } from "components/table";
import Time from "components/time";
import Heading from "components/heading";

const historyPageSize = 5;
const margin = "ml-1 mb-3";
const headerColClass = "px-6 py-3 text-left tracking-wider";
const rowColClass = "px-6 px-6 py-1 text-left";

export default function HistoryCard({ payload }) {
  const [historyPage, setHistoryPage] = useState(0);

  if (!payload) {
    return;
  }

  const history = payload.history;
  if (!history || history.length === 0) {
    return <></>;
  }

  let currentView = history
    .sort((a, b) => b.time - a.time)
    .slice(historyPage * historyPageSize, historyPage * historyPageSize + historyPageSize);

  if (!currentView) {
    currentView = [];
  }

  const historyColumnColumns = [
    {
      label: "Time",
      field: "time",
      width: "w-5",
    },
    {
      label: "Username",
      field: "username",
      width: "w-5",
    },
    {
      label: "Message",
      field: "message",
      width: "w-36",
    },
  ];

  return (
    <div className={margin}>
      <div className="flex items-center justify-between">
        <div className="w-56" />
        <Heading>History</Heading>
        <div className="flex items-center justify-start">
          <Paginator
            page={historyPage}
            setPage={setHistoryPage}
            currentPageSize={currentView.length}
            pageWindowSize={historyPageSize}
            totalEntries={history.length}
          />
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            {historyColumnColumns.map((c, i) => {
              return (
                <th key={`historyHeaderCard-${i}`} className={`${headerColClass} ${c.width}`}>
                  {c.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {currentView.map((c, i) => {
            return (
              <tr key={`historyColumnCard1-${i}`}>
                {historyColumnColumns.map((xc, xi) => {
                  let content = c[xc.field];

                  if (xc.field === "time") {
                    content = <Time time={content} />;
                  }

                  return (
                    <td key={`historyColumnCard2-${xi}`} className={`${rowColClass} ${xc.width}`}>
                      {content}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

HistoryCard.propTypes = {
  payload: PropTypes.object,
};
