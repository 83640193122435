import React, { useRef, useImperativeHandle, forwardRef, useState } from "react";
import PropTypes from "prop-types";

const TextArea = forwardRef(({
  label,
  className,
  wrapperClassName,
  noResize,
  placeholder,
  id,
  value,
  onChange,
  ...otherProps
}, ref) => {
  const textareaRef = useRef(null);
  const [timer, setTimer] = useState(null);

  // Expose the clear method to the parent
  useImperativeHandle(ref, () => ({
    clear: () => {
      if (textareaRef.current) { 
        textareaRef.current.value = "";
        onChange(""); // Notify the parent about the change
      }
    }
  }));

  const handleInputChange = (e) => {
    // Clear the existing timer
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(() => onChange(e.target.value), 1000)); 
  };

  return (
    <div className={`relative ${className || ""}`}>
      <textarea
        id={id}
        ref={textareaRef}
        className={`peer
          p-4 rounded-md bg-textBoxColor 
          opacity-75 outline outline-0
          border-solid w-full
          border-2 border-borderColor
          focus:bg-textBoxFocusColor text-contrastChangeText ${className || ""} ${noResize ? "resize-none" : ""}`}
        placeholder={" "}
        defaultValue={value}
        // value={value} omitted for performance
        onBlur={(e) => {
          return onChange(e.target.value);
        }}
        onChange={handleInputChange}
        {...otherProps}
      />
      <label
        htmlFor={id}
        className="select-none absolute text-sm text-contrastChangeText text-textBoxPlaceholderText
          duration-300 transform -translate-y-4 scale-75 top-4 z-2 origin-[0] 
          start-2.5 peer-focus:text-blue-600 
          peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
          peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4
          rtl:peer-focus:left-auto"
      >
        {placeholder}
      </label>
    </div>
  );
});

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  noResize: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired, // Ensure onChange is required
};

TextArea.displayName = "TextArea";

export default TextArea;
