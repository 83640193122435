/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

import { SubmissionList, Submissions } from "./submit";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import {
  SubmissionFrontendPath,
  ListPostfix,
  DeletePostfix,
  AuthorFrontendPath,
  GamesFrontendPath,
  ReviewFrontendPath,
  BlockListFrontendPath,
} from "constants/routing/frontend";
import DeleteSubmission from "./submit/pages/delete";
import AuthorSubmission from "./submit/pages/author";
import { GameSubmission } from "./submit/pages";
import BlockListSubmission from "./submit/pages/blockList";

export default function SubmissionRoutes() {
  const hiddenLinks = [
    {
      text: "Submit",
      path: SubmissionFrontendPath + ListPostfix,
      pathPart: ListPostfix + "/*",
      element: <Submissions />,
    },
    {
      text: "Delete",
      path: SubmissionFrontendPath + DeletePostfix,
      pathPart: DeletePostfix,
      element: <DeleteSubmission />,
    },
    {
      text: "Author",
      path: SubmissionFrontendPath + AuthorFrontendPath,
      pathPart: AuthorFrontendPath + "/:queueId?",
      element: <AuthorSubmission />,
    },
    {
      text: "Game",
      path: SubmissionFrontendPath + GamesFrontendPath,
      pathPart: GamesFrontendPath + "/:queueId?",
      element: <GameSubmission/>,
    },
    {
      text: "Review",
      path: SubmissionFrontendPath + ReviewFrontendPath,
      pathPart: ReviewFrontendPath + "/:queueId?",
      element: <></>,
    },
    {
      text: "Block List",
      path: SubmissionFrontendPath + BlockListFrontendPath,
      pathPart: BlockListFrontendPath + "/:queueId?",
      element: <BlockListSubmission />,
    },
  ];

  return (
    <>
      <SeoHeader pageTitle={"Submission"} />

      <Routes>
        {hiddenLinks.map(({ pathPart, element }, i) => {
          return <Route key={`submissionTabRoute-${i}`} path={pathPart} exact element={element} />;
        })}

        {SubmissionList.map(({ pathPart, element }, i) => {
          return <Route key={`submissionRoute-${i}`} path={pathPart} exact element={element} />;
        })}
      </Routes>
    </>
  );
}
