/** LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";

/* CONSTANTS */
import Title from "components/title";
import TextBox from "components/textBox";
import { RegistrationBackendPath } from "constants/routing/backend";
import Card from "components/card";
import { Link } from "react-router-dom";
import { HelpFrontendPath, LoginFrontendPath, RulesFrontendPath, UserFrontendPath } from "constants/routing/frontend";
import Checkbox from "components/checkBox";
import CaptchaComponent from "components/captcha";

import { CaptchaBackendPath, SearchBackendPostfix, GetBackendPostfix } from "constants/routing/backend";
import Button from "components/button";
import InlineLoader from "components/loaderInline";

/* ICONS */
import { FaCheckCircle as SuccessIcon } from "react-icons/fa";
import { HiXCircle as FailureIcon} from "react-icons/hi";
import { Tooltip } from "../../../node_modules/react-tooltip/dist/react-tooltip";

export default function Registration() {
  const [username_, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [termsAgree, setTermsAgree] = useState(false);

  const [captcha, setCaptcha] = useState({});
  const [showCaptcha, setShowCaptcha] = useState(true);

  const [isLoadingUsernameValidation, setIsLoadingUsernameValidation] = useState(false);
  const [usernameErrors, setUsernameErrors] = useState([]);
  const [isUsernameGood, setIsUsernameGood] = useState(false);

  const [securityAnswer, setSecurityAnswer] = useState("");

  useEffect(() => {
    if (!showCaptcha) {
      setCaptcha(null);
      return;
    }

    sendCaptchaEvent();
  }, [showCaptcha]);

  useEffect(() => {
    validateUsername();
  }, [username_]);

  const usernameAvailabilityRequest = async () => {
    const body = {
      username: username_,
    };
  
    try {
      const resp = await fetchPost(RegistrationBackendPath + "/usernameAvailability", body);
      return resp.isAvailable;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  const registration1Request = () => {
    setIsLoading(true);

    const body = {
      hasAcceptedTerms: termsAgree,
      username: username_,
      email: email,
      securityAnswer: securityAnswer,
    };

    fetchPost(RegistrationBackendPath + "/signUp/1", body)
      .then((resp) => {
        toast.success("Registration module success! Check your email.");
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendCaptchaEvent = () => {
    const ppp = {
      entityType: "user",
      entityId: "",
    };

    setIsLoading(true);
    fetchPost(CaptchaBackendPath + GetBackendPostfix, ppp)
      .then((resp) => {
        setCaptcha(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyCaptchaEvent = (coords, clear) => {
    fetchPost(CaptchaBackendPath + SearchBackendPostfix, { coords })
      .then((resp) => {
        setCaptcha(null);
        setShowCaptcha(false);
        toast.success("Captcha passed.");
      })
      .catch((resp) => {
        toast.error(resp.message);
        sendCaptchaEvent();
      });
  };

  const CaptchaModule = () => {
    return (
      <>
        {(!captcha || Object.keys(captcha).length === 0) || (
          <div className="flex justify-center">
            <CaptchaComponent data={captcha} refreshEvent={sendCaptchaEvent} confirmEvent={verifyCaptchaEvent} />
          </div>
        )}

        <div className="flex justify-end">
          {
            showCaptcha || <Button className="rounded-lg" onClick={() => {
              setShowCaptcha(true);
            }}>Retry CAPTCHA
            </Button>
          }
        </div>
      </>    
    );
  };

  const containerClass = "flex items-center justify-start col-span-12";

  const validateUsername = async() => {
    var isGood = true;
    const createLine = (text, pass) => {
      if (pass) {
        return <div className={containerClass}><SuccessIcon className="mr-2 text-green-500" />{text}<br/></div>;
      }
      isGood = false;
      return <div className={containerClass}><FailureIcon className="mr-2 text-red-500 col-span-12" />{text}<br/></div>;
    };

    const temp = [];
    temp.push(createLine("Username doesn't contain special characters.", /^[A-Za-z0-9\-_\s]{0,}/.test(username_)));
    temp.push(createLine("Username must be 3 to 20 characters long.", /^.{3,20}$/.test(username_)));
    temp.push(createLine("Username is available.", await usernameAvailabilityRequest()));

    setIsUsernameGood(isGood);
    setUsernameErrors(temp);
  };

  const enableRegisterButton = !captcha && isUsernameGood && termsAgree && securityAnswer && email;

  return (
    <>
      <SeoHeader pageTitle={"Registration"} />
      <Loader isLoading={isLoading} />

      <Title>Registration</Title>

      <div className="space-y-4 mb-3">
        <Card title="Registration Preface">
          <div className="text-left">
            Welcome to the registration process. Before proceeding,{" "}
            <Link to={HelpFrontendPath + RulesFrontendPath} target="_blank">
              please navigate to the rules
            </Link>{" "}
            and indicate you understand them by ticking the checkbox below.
          </div>
          <div className="flex justify-start mt-5">
            <Checkbox
              checked={termsAgree}
              onChange={setTermsAgree}
              label={"I have read, understand, and accept the rules and privacy policy in full."}
            />
          </div>
          <div className="col-span-12 flex justify-center mt-20">
            {"Already have an account?"}
            <Link to={UserFrontendPath + LoginFrontendPath} className=" ml-1">
              Sign In!
            </Link>
          </div>
        </Card>

        <Card title="Form">
          <div className="grid grid-cols-12 gap-3">
            <TextBox
              id={"username-textbox"}
              className={"w-full col-span-11"}
              placeholder={"Username"}
              value={username_}
              onChange={(x)=> {
                setUsername(x);
              }}
            />

            <div data-tooltip-id="tooltip" className="w-10 max-md:hidden grid grid-cols-1 gap-3">
              <InlineLoader isSuccess={isUsernameGood} isLoading={isLoadingUsernameValidation} />
              <Tooltip id="tooltip" place="top" effect="solid" className="text-left">
                {usernameErrors}
              </Tooltip>
            </div>

           
          </div>

          <div className="flex justify-start gap-3 md:hidden mt-3 text-left">
            <div>
              {usernameErrors}
            </div>
          </div>


          <div className="grid grid-cols-12 gap-3 mt-3">
            <TextBox 
              id={"textbox-email"} 
              className={"mb-3 w-full col-span-6"} 
              placeholder={"Email"} 
              value={email} 
              onChange={setEmail} />
          </div>



          <div className="col-span-12 flex justify-end mt-2 text-xs">
            A valid email is required for a registration confirmation email. 
            Only one email per account.
          </div>
        </Card>

        <Card title="Security Checks">
          <div className="grid grid-cols-12 gap-1 mb-3 mt-3">
            <div className="col-span-12 text-left">{"What does the dinosaur named \"Romh\" do when he's sick? He ____."}</div>
            <TextBox
              id={"textbox-romh"}
              className={"mb-3 w-full col-span-12"}
              placeholder={"Security question answer"}
              value={securityAnswer}
              onChange={setSecurityAnswer}
            />
          </div>
          <div className="flex justify-center">
            {CaptchaModule()}
          </div>

          <div className="col-span-12 flex justify-end mt-5 text-xs">
            <Button className="mt-3 rounded-lg" disabled={!enableRegisterButton} onClick={registration1Request}>
            Submit
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
