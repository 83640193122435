/* LIBS */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import MultiSelectAutocomplete2 from "components/multiSelectAutocomplete2";
import TextArea from "components/textArea";
import TextBox from "components/textBox";
import Card from "components/card";
import Heading from "components/heading";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import DropzoneComponent from "components/dropZone";
import { allowedImageType } from "constants/models";
import { Link } from "react-router-dom";
import { processFilesUrl } from "../fileHandle";

/* CONSTANTS */
import {
  FieldValuesBackendPostfix,
  ContentBackendPath,
  FieldsValuesBackendPostfix,
  QueueBackendPostfix,
  CreateBackendPostfix,
  UpdateBackendPostfix,
  NewsBackendPath,
  GetBackendPostfix,
  QueueBackendPath,
} from "constants/routing/backend";
import {
  ContentFrontendPath,
  DatabaseFrontendPath,
  EntryPostfix,
  ListPostfix,
  SubmissionQueueFrontendPath,
} from "constants/routing/frontend";
import { PermissionCreateTags } from "constants/permissions";

/* SERVICES */
import { UserContext } from "context/user";

export default function NewsSubmission() {
  const [isLoading, setIsLoading] = useState(false);
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [fileScreenshot, setFileScreenshot] = useState([]);

  const [entityId, setEntityId] = useState("");

  const [contentIds, setContentIds] = useState([]);
  const [contentLabel, setContentLabel] = useState([]);

  const [historyComment, setHistoryComment] = useState("");

  const { userContext } = useContext(UserContext);
  const { username , permissions } = userContext;

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const navigate = useNavigate();

  const markdownWidth = "md:col-span-3 col-span-6";

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and hack");
      return;
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }
  }, []);

  useEffect(() => {
    setEntityId(entityQueryId);
  }, [entityQueryId]);

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(NewsBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setHooks(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validationCheck = () => {
    var pass = true;

    if (tags.length === 0) {
      toast.error("Must select at least one tag");
      pass = false;
    }

    if (!description) {
      toast.error("Description must be filled out.");
      pass = false;
    }

    if (!headline) {
      toast.error("Must enter a headline.");
      pass = false;
    }

    if (!entityId){
      toast.error("Entity id cannot be empty.");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const temp = [];
    fileScreenshot.forEach((x) => {
      temp.push(x.ConvertToRequest());
    });

    const isUpdate = queueId !== null;

    var content = [];
    contentIds && contentIds.forEach( (x, i) => {
      content.push({contentId: x, contentTitle: contentLabel[i]});
    });

    const params = {
      queueId: queueId,
      entityId: entityId,

      content: content,

      title: headline,
      text: description,
      tags: tags,

      images: temp,

      historyComment: historyComment,
    };

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    fetchPost(NewsBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success(`Entry ${isUpdate ? "updated" : "created"}.`);
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {
        const d = JSON.parse(e.queueEntry.data);
        setHooks(d);
        setEntityId(e.queueEntry.entityId);
        setHistoryComment(e.queueEntry.historyComment);
      })
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  const setHooks = (d) => {
    setHeadline(d.title);
    setDescription(d.text);

    setContentLabel(d.content && d.content.map((x) => x.contentTitle));
    setContentIds(d.content && d.content.map((x) => x.contentId));

    setTags(d.tags);

    processFilesUrl(d.imagesUrl, setFileScreenshot);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityQueryId ? "Edit News" : "Submit News"}</Title>

      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={headline}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-6 w-full"}
            placeholder={"Headline"}
            onChange={setHeadline}
          />
          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-6"
            placeholder="Tags"
            field="tags"
            searchUri={NewsBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
            isCreatable={permissions.includes(PermissionCreateTags)}
          />
        </div>
      </Card>
      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className="max-lg:col-span-6 md:col-span-3">
            <Heading>Content</Heading>
            <MultiSelectAutocomplete2
              placeholder="Content"
              field="title"
              searchUri={ContentBackendPath}
              searchPostfix={FieldsValuesBackendPostfix}
              isMulti={true}
              label={contentLabel}
              setLabel={setContentLabel}
              value={contentIds}
              setValue={setContentIds}
            />
          </div>
          <div className="max-lg:col-span-6 md:col-span-3">
            <Heading>Preview</Heading>
            <ol>
              {contentIds && contentIds.map((x, i) => {
                return (
                  <li key={`content-link-${i}`}>
                    <Link 
                      target="_blank" rel="noreferrer" 
                      to={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix + x}>
                      {contentLabel[i]}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"Description"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full"
              className={"h-80"}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <div className={markdownWidth}>
            <Heading>Preview</Heading>
            <div className="col-span-3 overflow-scroll h-80">
              <Markdown className="w-full">{description}</Markdown>
            </div>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"File Upload"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={"col-span-12"}>
            <Heading>Images</Heading>
            <DropzoneComponent
              files={fileScreenshot}
              setFiles={setFileScreenshot}
              allowedFileTypes={allowedImageType}
              allowMultiple
              fileLimit={4}
            />
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
          Submit
        </Button>
      </div>
    </>
  );
}
