/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import MultiSelect from "../multiSelect";
import { autocompleteWindowMax, newId } from "constants/models";

// https://codepen.io/mael2210/pen/EgQveY
export default function MultiSelectAutocomplete({
  value,
  setValueHook,
  valueList,
  setValueList,
  valueAutocomplete,
  setValueAutocomplete,

  options,
  placeholder,
  allowCreation,
  className,
  isMulti,
  disabled,

  label,

  ...otherProps
}) {
  if (!options) {
    options = [];
  }

  if (!value) {
    value = [];
  }

  return (
    <MultiSelect
      {...otherProps}
      className={className}
      placeholder={placeholder}
      isMulti={false}
      onSelect={(e) => {
        setValueHook(e.value);
      }}
      onCreate={(e) => {
        setValueHook(newId);
        setValueList({ NEW: e.value });
      }}
      onClear={() => {
        setValueHook("");
        setValueAutocomplete("");
      }}
      options={options}
      onInputChange={(value, meta) => {
        if (value.length > valueAutocomplete.length && Object.keys(valueList).length < autocompleteWindowMax) {
          return;
        }

        if (value === "" && (meta.action === "set-value" || meta.action === "menu-close" || meta.action === "input-blur")) {
          return;
        }
        setValueAutocomplete(value);
      }}
      value={value}
      disabled={disabled}
    />
  );
}

MultiSelectAutocomplete.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  allowCreation: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,

  label: PropTypes.string,

  value: PropTypes.any,
  setValueHook: PropTypes.func,
  setValueList: PropTypes.func,
  setValueAutocomplete: PropTypes.func,

  valueList: PropTypes.any,
  valueAutocomplete: PropTypes.any,
};
