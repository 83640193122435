/* LIBS */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";

/* CUSTOMS */
import { Table } from "components/table";
import Heading from "components/heading";
import Time from "components/time";
import PopupBox from "components/popupBox";
import Pill from "components/pill";

import { 
  BsCheckLg as CheckMarkIcon, 
  BsXLg as XMarkIcon 
} from "react-icons/bs";

export default function Review({ payload }) {
  if (!payload || !payload.review) {
    return;
  }

  payload.review.sort((a, b) => b.createdDate - a.createdDate);

  const rightText = "px-6 text-left";
  const headerColClass = "px-6 py-3 text-left tracking-wider truncate";
  const margin = "ml-1 mb-3";

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [reviewIndex, setReviewIndex] = useState(0);

  const modal = () => {
    if (payload.review.length === 0) {
      return;
    }

    return (
      <PopupBox
        title={payload.review[reviewIndex].reviewTitle}
        onClose={() => {
          setFilterModalOpen(false);
        }}
        isOpen={filterModalOpen}
        scrollOverflow={true}
      >
        <Markdown className="text-left mr-3 text-contrastChangeText">{payload.review[reviewIndex].review}</Markdown>
      </PopupBox>
    );
  };

  return (
    <div className={margin}>
      {modal()}

      <Heading>Reviews</Heading>
      <Table>
        <thead className="">
          <tr>
            <td className={`w-40 ${headerColClass}`}>Headline</td>
            <td className={`w-20 max-lg:hidden ${headerColClass}`}>User</td>
            <td className={`w-10 max-lg:hidden ${headerColClass}`}>Date</td>
            <td className={`w-10 max-lg:hidden ${headerColClass}`}>Version</td>
            <td className={`w-10 ${headerColClass}`}>Recommended</td>
          </tr>
        </thead>
        <tbody>
          {payload.review && payload.review.map((c, i) => {

            var text = c.isRecommended ? <CheckMarkIcon /> : <XMarkIcon />;
            var color = c.isRecommended ? "green" : "red";
            var content = <Pill text={text} color={color} />;

            return (
              <tr key={`linkCard-${i}`}>
                <td className={`w-40 ${rightText}`}><Link onClick={() => {
                  setReviewIndex(i);
                  setFilterModalOpen(true);
                }}>{c.reviewTitle}</Link></td>
                <td className={`w-20 max-lg:hidden ${rightText}`}>{c.userName}</td>
                <td className={`w-10 max-lg:hidden ${rightText}`}>{<Time time={c.createdDate} />}</td>
                <td className={`w-10 max-lg:hidden ${rightText}`}>{c.version}</td>
                <td className={`w-10 ${rightText}`}>{content}</td>
              </tr>
            );
          }
          )}
        </tbody>
      </Table>
    </div>
  );
}

Review.propTypes = {
  payload: PropTypes.object,
};
