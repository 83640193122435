/* LIBS */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import PopupBox from "components/popupBox";
import Button from "components/button";
import PropTypes from "prop-types";
import { fetchPost } from "lib/fetch";

/* CUSTOMS */
import MultiSelect from "components/multiSelect";
import MultiSelectAutocomplete from "components/multiSelectAutocomplete";
import { ToLabelValueFromMap, ToLabelValueFromSource, ToLabelValue } from "lib/strings";
import TextBox from "components/textBox";

/* CONSTANTS */
import { AuthorBackendPath, FieldValuesBackendPostfix, ContentBackendPath, FieldsValuesBackendPostfix } from "constants/routing/backend";

export default function CreditModal({
  modalOpen,
  setModalOpen,

  creditList,
  setCreditList,

  creditIndex,
  setCreditIndex,

  actionType,
}) {
  const [authorList, setAuthorList] = useState({});
  const [authorAutocomplete, setAuthorAutocomplete] = useState("");
  const [creditEntry, setCreditEntry] = useState({});
  const [creditTypeList, setCreditTypeList] = useState("");

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    switch (actionType) {
    case "Add": {
      setCreditEntry({ creditId: "", creditName: "", creditType: "", comment: "" });
      break;
    }
    case "Edit": {
      const entry = creditList[creditIndex];
      setCreditEntry(entry);
      setAuthorAutocomplete(entry.creditName);
      break;
    }
    default:
      throw new Error(`CreditModal: invalid modal type: ${actionType}`);
    }

    authorRequest();
    getCreditType();
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) {
      return;
    }

    authorRequest();
  }, [authorAutocomplete]);

  const onClose = () => {
    setCreditIndex(0);
    setModalOpen(false);
  };

  const onSave = () => {
    switch (actionType) {
    case "Add": {
      const temp = [...creditList];
      temp.push(creditEntry);
      setCreditList(temp);
      break;
    }
    case "Edit": {
      const temp = [...creditList];
      temp[creditIndex] = creditEntry;
      setCreditList(temp);

      break;
    }
    default:
      console.error(`CreditModal: invalid modal type: ${actionType}`);
    }

    onClose();
  };

  const getCreditType = () => {
    const params = {
      field: "credits.type",
    };

    fetchPost(ContentBackendPath + FieldValuesBackendPostfix, params)
      .then((resp) => {
        setCreditTypeList(resp);
      })
      .catch((thrown) => {
        console.error(thrown);
      });
  };

  const authorRequest = () => {
    const params = {
      field: "title",
      value: authorAutocomplete,
    };

    fetchPost(AuthorBackendPath + FieldsValuesBackendPostfix, params)
      .then((resp) => {
        setAuthorList(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  const setAuthor = (value) => {
    const temp = { ...creditEntry };
    temp.creditId = value;
    temp.creditName = authorList[value];
    setCreditEntry(temp);
  };

  const setType = (value) => {
    const temp = { ...creditEntry };
    temp.type = value.label;
    setCreditEntry(temp);
  };

  const setComment = (e) => {
    const temp = { ...creditEntry };
    temp.comment = e;
    setCreditEntry(temp);
  };

  const buttonDisabled = !creditEntry.creditId || !creditEntry.type;

  return (
    <PopupBox title={`${actionType} Credit`} onClose={() => onClose()} isOpen={modalOpen}>
      <MultiSelectAutocomplete
        className="col-span-2 mb-3"
        placeholder="Author"
        allowCreation={true}
        isMulti={false}
        options={ToLabelValueFromMap(authorList)}
        value={ToLabelValueFromSource(creditEntry.creditId, ToLabelValueFromMap(authorList))}
        setValueHook={setAuthor}
        valueAutocomplete={authorAutocomplete}
        setValueAutocomplete={setAuthorAutocomplete}
        valueList={authorList}
        setValueList={setAuthorList}
      />

      <MultiSelect
        className="col-span-2 mb-3"
        placeholder="Credit Type"
        isMulti={false}
        onSelect={setType}
        options={ToLabelValueFromMap(creditTypeList)}
        value={ToLabelValue(creditEntry.type)}
        onInputChange={() => {}}
        onChange={() => {}}
      />

      <TextBox id={"note-box"} className={"mb-3 w-full"} placeholder={"Note"} onChange={setComment} />

      <Button className="rounded-md mb-3" disabled={buttonDisabled} onClick={onSave}>
        {actionType}
      </Button>
    </PopupBox>
  );
}

CreditModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,

  creditList: PropTypes.arrayOf(
    PropTypes.shape({
      creditId: PropTypes.string,
      creditName: PropTypes.string,
      creditType: PropTypes.string,
      comment: PropTypes.string,
    })
  ),
  setCreditList: PropTypes.func.isRequired,

  creditIndex: PropTypes.number.isRequired,
  setCreditIndex: PropTypes.func.isRequired,

  actionType: PropTypes.string.isRequired,
  columns: PropTypes.object,
};
