/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Title from "components/title";

/* CUSTOMS */
import Paginator from "components/paginator";
import Loader from "components/loader";
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import { SortableTable } from "components/sortableTable";
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import { pageSize } from "constants/models";
import {
  GetBackendPostfix,
  SearchBackendPostfix,
  MetadataBackendPostfix,
  HistoryMetricBackendPath,
  UserBackendPostfix,
  AdminBackendPath,
  SessionBackendPostfix,
} from "constants/routing/backend";
import { filterOperatorShould, requestFormatFilter } from "lib/elastic";
import HistoryCard from "pages/history";
import {
  ContentFrontendPath,
  DeletePostfix,
} from "constants/routing/frontend";
import Tags from "../../database/tags";

/* SERVICES */
import { UserContext } from "context/user";
import QueueManageButtons from "../../database/queueManageButton";
import DeletionNoticeCard from "../../database/deletionNotice";
import { Table } from "components/table";
import Heading from "components/heading";
import Time from "components/time";
import Pill from "components/pill";
import Button from "components/button";
import { PermissionAdmin } from "constants/permissions";

export default function UserEntry() {
  const [payloadUser, setPayloadUser] = useState(null);
  const [payloadSessions, setPayloadSessions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { username , permissions } = userContext;

  useEffect(() => {
    userGetRequest();
    userSessionGetRequest();
  }, [id]);

  const userGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(AdminBackendPath + UserBackendPostfix + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadUser(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userSessionGetRequest = () => {
    setIsLoading(true);

    const body = {
      userId: id,
    };

    fetchPost(AdminBackendPath + SessionBackendPostfix + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadSessions(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadSessions(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const userSessionKill = (sessionId) => {
    setIsLoading(true);

    const body = {
      userId: id,
      sessionId: sessionId,
    };

    fetchPost(AdminBackendPath + SessionBackendPostfix + DeletePostfix, body)
      .then((resp) => {
        toast.success("Session killed");
        userSessionGetRequest();
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const userAbout = () => {
    if (!payloadUser){
      return;
    }

    const profileImage = () => {
      if (!payloadUser.thumbnailUrl) {
        return;
      }

      return (
        <div className="flex items-center justify-center">
          <img src={payloadUser.thumbnailUrl.url} alt=""/>
        </div>
      );
    };

    const permissionsTags = () => {
      if (!payloadUser.permissions) {
        return;
      }

      return (
        <div className="text-left">
          <Heading>Permissions</Heading>
          <div className="flex flex-wrap">
            {payloadUser.permissions &&
                payloadUser.permissions.map((x, i) => {
                  return <Pill key={`bullet-${x.id}-${i}`} color={"blue"} className={"m-1"} text={x} />;
                })}
          </div>
        </div>
      );
    };


    const emailModule = () => {
      if (!payloadUser.email) {
        return;
      }

      return (
        <div className="text-left">
          <Heading>Email</Heading>
          <div className="flex flex-wrap">
            {payloadUser.email }
          </div>
        </div>
      );
    };

    const buttonGroup = () => {
      return (
        <div className="flex justify-end m-4 space-x-4">
          <Button className="rounded-lg">
          Force Password Reset
          </Button>
        </div>
      );
    };

    return (
      <Card className={margin} title={"Details"}>
        <div>
          {profileImage()}
          {emailModule()}
          {permissionsTags()}
          {<Tags payload={payloadUser} />}
          {buttonGroup()}
        </div>
      </Card>
    );
  };

  const headerColClass = "px-6 py-3 text-left tracking-wider";
  const rightText = "px-6 text-left break-words";

  const userSession = () => {
    return (
      <>
        <Heading>Active Sessions</Heading>
        <Table>
          <thead>
            <tr>
              <td className={`w-20 ${headerColClass}`}>IP Address</td>
              <td className={`w-20 ${headerColClass}`}>User Agent</td>
              <td className={`w-20 ${headerColClass}`}>Kill Session</td>
            </tr>
          </thead>
          <tbody>
            {payloadSessions && payloadSessions.map((c, i) => {
              return (
                <tr className="ml-3" key={`activeSessions-${i}`}>
                  <td className={rightText}>{c.ipAddress}</td>
                  <td className={rightText}>{c.userAgent}</td>
                  <td className={rightText}>
                    <Button 
                      onClick={() => userSessionKill(c.sessionId)}
                      className="rounded-lg" secondary={true}>
                        Kill Session
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const [historyPayload, setHistoryPayload] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("history.time");
  const [sortDirection, setSortDirection] = useState("asc");
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    historyMetadataRequest();
  }, []);

  const historyMetadataRequest = () => {
    fetchPost(HistoryMetricBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, payloadUser]);

  const hackRequest = () => {
    if (!payloadUser) {
      return;
    }

    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "history.userId",
        operator: filterOperatorShould,
        value: id,
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(HistoryMetricBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setHistoryPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contributionCard = () => {
    if (!historyPayload) {
      return;
    }

    const columns = [
      {
        label: "Time",
        field: "history.time",
        width: "w-10 max-md:hidden",
        dataType: "dateTime",
      },
      {
        label: "Entity ",
        field: "entityType",
        width: "w-5",
        dataType: "write",
      },
      {
        label: "Title",
        idField: "entityId",
        field: "entityTitle",
        width: "w-20",
        dataType: "link",
        to: (data) => {
          return `/database/${data.entityType}/entry/`;
        },
      },
      {
        label: "Username",
        field: "history.username",
        width: "w-10",
        dataType: "write",
      },
      {
        label: "Message",
        field: "history.message",
        width: "w-40",
        dataType: "write",
      },
    ];

    const paginationElement = () => {
      return (
        <Paginator
          page={page}
          setPage={setPage}
          currentPageSize={historyPayload.entries && historyPayload.entries.length}
          pageWindowSize={pageSize}
          totalEntries={historyPayload && historyPayload.total}
        />
      );
    };

    if (historyPayload.total === 0) {
      return <></>;
    }

    return (
      <>
        <Title>Contributions</Title>
        <div className="flex items-center justify-end">{paginationElement()}</div>
        <SortableTable
          data={historyPayload && historyPayload.entries}
          metadataColumn={metadata && metadata.columns}
          columns={columns}
          setSortField={setSortField}
          setSortDirection={setSortDirection}
          sortField={sortField}
          sortDirection={sortDirection}
        />
      </>
    );
  };

  const topUserAgentCard = () => {
    if (!payloadUser || !payloadUser.userAgents) {
      return;
    }

    return (
      <>
        <Card className={margin} title={"User Agents"}>
          <ol className="text-left">
            { payloadUser.userAgents.map((x, i) => {
              return(
                <li key={`userAgents-${i}`}>
                  {x}
                </li>
              );
            })
            }
          </ol>
        </Card>
      </>
    );
  };


  const topIpAddressCard = () => {
    if (!payloadUser || !payloadUser.ipAddresses) {
      return;
    }

    return (
      <>
        <Card className={margin} title={"IP Addresses"}>
          <ol className="text-left">
            { payloadUser.ipAddresses.map((x, i) => {
              return(
                <li key={`ipAddresses-${i}`}>
                  {x}
                </li>
              );
            })
            }
          </ol>
        </Card>
      </>
    );
  };

  const loginActivityCard = () => {
    if (!historyPayload || !payloadUser.loginHistory) {
      return;
    }

    payloadUser.loginHistory.sort((a, b) => b.dateTime - a.dateTime);

    return (
      <>
        <Heading>Login History</Heading>
        <Table>
          <thead>
            <tr>
              <td className={`w-20 ${headerColClass}`}>Time</td>
              <td className={`w-20 ${headerColClass}`}>IP Address</td>
              <td className={`w-80 ${headerColClass}`}>User Agent</td>
            </tr>
          </thead>
          <tbody>
            {payloadUser.loginHistory.map((c, i) => {
              return (
                <tr className="ml-3" key={`loginHistory-${i}`}>
                  <td className={rightText}><Time time={c.dateTime} customFormat="YYYY MMM DD HH:mm UTC" /></td>
                  <td className={rightText}>{c.ipAddress}</td>
                  <td className={rightText}>{c.userAgent}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  /*if (!payloadUser) {
    return;
  }*/

  if (!username){
    return;
  }

  if (!permissions.includes(PermissionAdmin)) {
    toast.error("You do not have permission to view this page.");
    return;
  }

  var authorName = payloadUser && payloadUser.username;

  return (
    <>
      <SeoHeader
        pageTitle={"User"}
        pageSectionTitle={payloadUser && payloadUser.username}
      />

      <Loader isLoading={isLoading} />

      <Title>{authorName}</Title>

      {payloadUser ? (
        <QueueManageButtons id={id} frontendPath={ContentFrontendPath} entityType={"author"} isDeleted={payloadUser.isDeleted} />
      ) : null}

      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {userAbout()}
          {<HistoryCard payload={payloadUser} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {topUserAgentCard()}
          {topIpAddressCard()}
          {<DeletionNoticeCard payload={payloadUser}/>}
        </div>
      </div>

      <div className={`$w-full col-span-6 ${margin}`}>
        {userSession()}
        {loginActivityCard()}
        {contributionCard()}
      </div>
    </>
  );
}
