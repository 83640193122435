/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import { IsSortableField } from "lib/sortableField";

/* CUSTOMS */
import Time from "components/time";
import Pill from "components/pill";
import Button from "components/button";
import { Table } from "components/table";
import { Link } from "react-router-dom";

/* ICONS */
import { IoIosArrowDown as SortDownIcon, IoIosArrowUp as SortUpIcon } from "react-icons/io";
import { MdSort } from "react-icons/md";
import { BsCheckLg as CheckMarkIcon, BsXLg as XMarkIcon } from "react-icons/bs";
import { extractFromObjectWithPath } from "lib/extractFromObjectWithPath";

export const SortableTable = ({
  metadataColumn,
  columns,
  data,

  sortField,
  sortDirection,

  setSortField,
  setSortDirection,
}) => {
  const headerColClass = "px-4 py-3 text-left tracking-wider";
  const rowColClass = "px-4 py-3 text-left";

  return (
    <Table>
      <thead>
        <tr>
          {columns &&
            columns.map((c, i) => {
              let onClick = () => {};
              let content = c.label;
              const field = c.field;

              let isSortable = false;

              const sortFieldKey = c.sortField || field;

              if (metadataColumn && Object.prototype.hasOwnProperty.call(metadataColumn, sortFieldKey)) {
                isSortable = IsSortableField(metadataColumn[sortFieldKey].type);
              }

              if (isSortable) {
                const className = "ml-2";

                let icon = null;

                if (sortField && sortDirection && setSortDirection && setSortField) {
                  if (sortField === sortFieldKey) {
                    if (sortDirection === "asc") {
                      icon = <SortUpIcon className={className} />;
                      onClick = () => {
                        setSortDirection("desc");
                        setSortField(sortFieldKey);
                      };
                    } else {
                      icon = <SortDownIcon className={className} />;
                      onClick = () => {
                        setSortDirection("asc");
                        setSortField(sortFieldKey);
                      };
                    }
                  } else {
                    icon = <MdSort className={className} />;
                    onClick = () => {
                      setSortDirection("asc");
                      setSortField(sortFieldKey);
                    };
                  }
                }

                content = (
                  <div onClick={onClick} className={`flex items-center ${isSortable && "cursor-pointer"}`}>
                    {content}
                    {icon}
                  </div>
                );
              }

              return (
                <th key={`header-${i}`} className={`${headerColClass} ${c.width} `}>
                  {content}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((d, dataI) => {
            return (
              <tr key={`Tbody-${dataI}`}>
                {columns &&
                  columns.map((c, cI) => {
                    let className = `${c.width} text-xs`;
                    let content = null;
                    var fieldValue = extractFromObjectWithPath(c.field, d);
                    let onClick = c.onClick ?? (() => {});
                    var to = c.to;
                    let hoverText = null;

                    // Auto-linebreaks and word wraps long strings
                    let truncationStyle = "break-words";

                    switch (c.dataType) {
                    case "write":
                      if (Array.isArray(fieldValue)) {
                        content = fieldValue.join(", ");
                        hoverText = content;
                      } else if (typeof fieldValue === "boolean") {
                        let text = fieldValue ? <CheckMarkIcon /> : <XMarkIcon />;
                        let color = fieldValue ? "green" : "red";
                        content = <Pill text={text} color={color} />;
                      } else {
                        content = fieldValue;
                        hoverText = content;
                      }

                      break;
                    case "dateTimeAgo":
                      content = <Time time={fieldValue} fromNow />;
                      break;
                    case "dateTime":
                      content = <Time time={fieldValue} />;
                      break;
                    case "enableChip":
                      var text = fieldValue ? "Active" : "Disabled";
                      var color = fieldValue ? "green" : "red";
                      content = <Pill text={text} color={color} />;
                      hoverText = text;
                      break;
                    case "link": {
                      const id = extractFromObjectWithPath(c.idField, d);

                      if (typeof to === "function") {
                        to = to(d);
                      }

                      const url = to + id;

                      if (!fieldValue && id) {
                        fieldValue = "N/A";
                      }

                      content = <Link to={url}>{fieldValue}</Link>;

                      hoverText = fieldValue;
                      break;
                    }
                    case "button":
                      content = (
                        <Button className="rounded-md" secondary={c.buttonSecondary} onClick={() => onClick(d, dataI)}>
                          {c.buttonText ?? c.label}
                        </Button>
                      );
                      break;
                    case "lookup":
                      content = c.lookupFunc(fieldValue);
                      hoverText = content;
                      break;
                    case "custom":
                      content = c.custom(fieldValue);
                      break;
                    default:
                      console.error(`Invalid datatype: ${c.dataType}`);
                    }

                    return (
                      <td key={`td-${cI}`} className={`${rowColClass} ${truncationStyle} ${d.width} ${className || ""}`} title={hoverText}>
                        {content}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const validateCustom = (props, propName, componentName) => {
  if (!props[propName]) {
    return;
  }

  const { dataType, custom } = props[propName];
  if (dataType === "custom" && !custom) {
    return new Error(`The prop \`${propName}\` is required when dataType is "custom" in \`${componentName}\`.`);
  }
  return null;
};

SortableTable.propTypes = {
  metadataColumn: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      dataType: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      lookupFunc: PropTypes.func,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

      idField: PropTypes.string,

      sortField: PropTypes.string,

      custom: validateCustom,

      buttonSecondary: PropTypes.bool,
      buttonText: PropTypes.any,
    })
  ).isRequired,
  data: PropTypes.any,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,

  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};
